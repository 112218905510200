<template>
  <div
    v-loading="loading"
    tabindex="0"
    :class="`expression-container ${darkModeClass}`"
  >
    <div class="expressionBuilderTitleGroup">
      <h2 class="expressionBuilderTitle">
        {{ builderNameLan }}
      </h2>
      <div
        class="expressionBuilderOp"
        v-if="
          !isSourceARuleBuilder(source) &&
            nonEmptyValidExpressions &&
            showCopyOption &&
            finalExpression
        "
      >
        <el-button
          class="operationBtn"
          @click="initiateCopyExpressions"
          style="padding: 7px"
        >
          <div style="display: flex">
            <img
              class="bulkImg"
              :src="require('@/assets/icons/icon-copy.svg')"
              onload="SVGInject(this)"
            />
            <span>{{ __("Copy expression list") }}</span>
          </div>
        </el-button>
      </div>
      <div
        class="expressionBuilderOp"
        v-if="!isSourceARuleBuilder(source) && expressionCopyExists"
      >
        <el-button
          class="operationBtn"
          @click="pasteExpressions"
          style="padding: 7px"
        >
          <div style="display: flex">
            <img
              class="bulkImg"
              :src="require('@/assets/icons/icon-paste-replace.svg')"
              onload="SVGInject(this)"
            />
            <span>{{ __("Paste/replace expression list") }}</span>
          </div>
        </el-button>
      </div>
      <!--div
        class="expressionBuilderOp"
        v-if="!isSourceARuleBuilder(source) && expressionCopyExists"
        @click="clearCopy"
      >
        {{ __("Clear Copy") }}
      </div-->
    </div>
    <div
      style="display:flex; align-items: center; margin-top: 10px; margin-left: 25px;"
      v-if="!finalExpression"
    >
      <div class="warning-exclamation"></div>
      <div
        style="margin-bottom: 0; display: flex; font-size: 13px; color: #f9c127"
      >
        <div>
          <!--          Choose a function or click-->
          <!--          <span-->
          <!--            @click="resetExpressions"-->
          <!--            style="cursor:pointer; font-weight: 900"-->
          <!--            >reset</span-->
          <!--          >-->
          <!--          to generate an empty expression-->
          <!-- eslint-disable-next-line -->
          {{ __("saving this expression will remove the expression as no expression is selected to be evaluated") }}
        </div>
      </div>
    </div>
    <div class="expressionBuilderTable">
      <el-form>
        <el-scrollbar :native="false">
          <div style="max-height: 88vh">
            <el-table
              :cell-class-name="expressionCellClass"
              class="expression-builder"
              fit
              :data="expressionsToShow"
              style="width: 100%"
              :border="false"
              :span-method="rowColSpan"
            >
              <el-table-column
                v-if="!isSourceARuleBuilder(source)"
                width="250px"
                prop="name"
              >
                <template slot-scope="scope">
                  <el-form-item
                    class="expression-name"
                    :error="scope.row.error"
                  >
                    <div
                      style="display: flex; flex-direction: column;padding-left: 12px"
                    >
                      <div
                        class="super-text"
                        style="padding-left: 26px;margin-top: 4px"
                      >
                        <div>{{ __("EXPRESSION") }}</div>
                      </div>
                      <div
                        style="display: flex; flex-direction: row;align-items: center"
                      >
                        <el-tooltip
                          :content="__('Select to make it final expression')"
                          placement="bottom"
                        >
                          <el-radio
                            :disabled="
                              !isExpressionNameValid(scope.row.name) ||
                                !!scope.row.fixed
                            "
                            :value="isExpressionFinal(scope.row.name)"
                            :label="true"
                            @input="handleUserSelection(scope.row.name)"
                            style="display:flex; align-items:center;padding-top:2px"
                          >
                            <div>&nbsp;</div>
                          </el-radio>
                        </el-tooltip>

                        <el-input
                          :ref="`rename-box-${scope.row.name}`"
                          :id="`rename-box-${scope.row.name}`"
                          class="expression-rename-box"
                          maxlength="50"
                          show-word-limit
                          size="mini"
                          @focus="
                            setExpressionToCustomNameMapToUse(
                              scope.row,
                              'backup'
                            )
                          "
                          @blur="setExpressionToCustomNameMapToUse(scope.row)"
                          :value="customNameMap(scope.row)"
                          @input="updateCustomNameMap(scope.row)($event)"
                          @keyup.enter.native.exact.prevent.stop="
                            persistCustomNameMap(scope.row)
                          "
                          @keyup.esc.native.exact.prevent.stop="
                            resetCustomName(scope.row)
                          "
                          :placeholder="
                            getExpressionCustomName(scope.row.name).replace(
                              expressionCustomNamePrefix,
                              ''
                            )
                          "
                        >
                          <template slot="prepend">{{
                            expressionCustomNamePrefix
                          }}</template>
                        </el-input>
                      </div>
                    </div>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                prop="selectedFunction"
                class-name="function-column"
                width="300px"
              >
                <template slot-scope="scope">
                  <el-form-item>
                    <div
                      style="display: flex; flex-direction: column;padding-left: 12px;padding-right: 12px"
                    >
                      <div class="super-text" style="margin-top: -4px">
                        <div>{{ __("FUNCTION") }}</div>
                      </div>
                      <el-select
                        :ref="scope.row.name + 'SelectedFunctionRef'"
                        :disabled="!!scope.row.fixed"
                        class="w-full functionsList"
                        :popper-class="`functionDropDown ${darkModeClass}`"
                        v-model="scope.row.selectedFunction"
                        filterable
                        :placeholder="__('Select Function')"
                        @change="setParameters(scope.row, false)"
                        default-first-option
                      >
                        <el-option-group
                          v-for="group in operationsFor(source)"
                          :key="group.label"
                          :label="group.label"
                        >
                          <el-option
                            v-for="(item, index) in filterOperations(
                              group.operations
                            )"
                            :key="index"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-option-group>
                      </el-select>
                    </div>
                  </el-form-item>
                </template>
              </el-table-column>

              <el-table-column
                :key="item"
                v-for="(item, index) in getRangeOfParameters"
                :prop="String(index)"
                :min-width="calculateExpressionFieldMinWidth(index)"
              >
                <template
                  v-if="
                    index < parameterCountFor(scope.row.selectedFunction) &&
                      !(index === 0 && isSourceARuleBuilder(source))
                  "
                  slot-scope="scope"
                >
                  <el-form-item :error="scope.row.parameters[index].error">
                    <expression-field
                      :disabled="!!scope.row.fixed"
                      :ref="createParameterReference(scope.row, index)"
                      :value="scope.row.parameters[index]"
                      @input="handleParameterChange($event)(scope.row, index)"
                      @validate="validateRequiredField"
                      :expression="scope.row"
                      :complex-variables="complexVariables"
                      :xsi-subscriptions="xsiSubscriptionRules"
                      :expressionNames="
                        isSourceARuleBuilder(source)
                          ? []
                          : expressionNames(scope.row)
                      "
                      :expressions="expressions"
                      :custom-expression-names-map="expressionToCustomNameMap"
                      @keydown.ctrl.enter.native.prevent.stop="
                        !isJsonFunction(scope.row.selectedFunction) &&
                          validExpressionNames.includes(scope.row.name) &&
                          !evaluationTreeHasError(scope.row) &&
                          setUpTestValuesForExpressionAndEvaluate(scope.row)
                      "
                      @keydown.shift.enter.native.prevent.stop="saveExpression"
                      :dropDownClass="darkModeClass"
                      :use-secure-variables="useSecureVariables"
                    ></expression-field>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                class="expression-columns"
                :label="sourceARuleBuilderLan"
              >
                <el-table-column width="25px">
                  <template
                    slot-scope="scope"
                    v-if="
                      !isJsonFunction(scope.row.selectedFunction) &&
                        validExpressionNames.includes(scope.row.name) &&
                        !evaluationTreeHasError(scope.row)
                    "
                  >
                    <div
                      class="evaluate-expression"
                      @click="
                        setUpTestValuesForExpressionAndEvaluate(scope.row)
                      "
                    >
                      <span v-if="!scope.row.evaluating"
                        ><img src="/icons/eval.svg"
                      /></span>
                      <span v-else> <i class="el-icon-loading"></i></span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  width="300px"
                  class-name="expression-result-column"
                >
                  <template
                    slot-scope="scope"
                    v-if="
                      !isJsonFunction(scope.row.selectedFunction) &&
                        validExpressionNames.includes(scope.row.name)
                    "
                  >
                    <el-form-item>
                      <div v-if="!scope.row.evaluating">
                        <expression-builder-preview-output
                          classAttachments="expression-result"
                          :previewResult="scope.row.value"
                        />
                      </div>
                    </el-form-item>
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column class-name="cell-item-pointer " width="40px">
                <template slot-scope="scope">
                  <span
                    v-if="!scope.row.fixed && !isSourceARuleBuilder(source)"
                    class="expression-close-icon"
                    @click="removeExpression(scope.$index)"
                  >
                    <i class="el-icon-circle-close"></i>
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-scrollbar>
        <div class="actionButtons" style="width: 250px;margin-left: 20px">
          <el-button
            @click="saveExpression"
            :disabled="expressionsHasError"
            class="save"
          >
            {{ __("Save Changes") }}
          </el-button>
          <el-button @click="$emit('cancel')" class="cancel">
            {{ __("Cancel") }}
          </el-button>
        </div>
      </el-form>
      <el-drawer
        :with-header="false"
        :visible.sync="drawer"
        direction="rtl"
        size="300px"
        :modal-append-to-body="false"
        @keydown.enter.native.prevent.stop="
          evaluateExpression(
            getExpressionByName(expressionToEvaluate.expression_to_evaluate)
          )
        "
        @keydown.esc.native.prevent.stop="drawer = false"
      >
        <h3 class="variableDrawerTitle">{{ __("Set test values") }}</h3>
        <p class="variableDrawerSubtext">
          <!-- eslint-disable-next-line -->
          {{ __("The following field(s) require test data to evaluate the expression") }}
        </p>
        <variable-setter
          v-if="variablesToSetTestValues.length"
          v-model="variablesToSetTestValues"
        />

        <variable-setter
          v-if="jsonExpressionsToSetTestValues.length"
          v-model="jsonExpressionsToSetTestValues"
          :custom-items-name-map="expressionToCustomNameMap"
          :custom-item-name-prefix="expressionNamePrefix"
          title="expression"
        />

        <div class="actionButtons">
          <el-button
            @click="
              evaluateExpression(
                getExpressionByName(expressionToEvaluate.expression_to_evaluate)
              )
            "
            class="save"
          >
            {{ __("Set") }}
          </el-button>
          <el-button @click="drawer = false" class="cancel">
            {{ __("Cancel") }}
          </el-button>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import _ from "lodash";
import ExpressionField from "./ExpressionField";
import VariableSetter from "../VariableSetter";
import { evaluateExpression } from "@/api/services";
import { getComplexVariables } from "@/api/variables";
import { getXsiSubscriptionRules } from "@/api/xsisubscriptions";
import ExpressionBuilderPreviewOutput from "./ExpressionBuilderPreviewOutput";

export default {
  name: "ExpressionBuilder",
  components: {
    ExpressionField,
    VariableSetter,
    ExpressionBuilderPreviewOutput
  },
  props: {
    value: {
      required: true,
      type: String
    },
    resetValue: {
      required: true,
      type: String
    },
    new: {
      required: false,
      type: Boolean,
      default: false
    },
    source: {
      required: false,
      type: String,
      default: "all"
    },
    useSecureVariables: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState("app", {
      darkTheme: state => state.darkTheme
    }),

    ...mapState("canvas", {
      clickedNode: state => state.clickedNode
    }),
    ...mapState("expressionbuilder", {
      operations: state => state.operations,
      allowedOpsFor: state => state.allowedOpsFor,
      copiedExpressions: state => state.copiedExpressions
    }),
    ...mapGetters("variables", {
      variableNames: "variableNames",
      variables: "variables",
      cavVariables: "cavVariables",
      getSystemVariableNames: "getSystemVariableNames",
      secureVariableNames: "secureVariableNames",
      xSipVariableNames: "xSipVariableNames",
      cavVariablesNames: "cavVariablesNames"
    }),
    ...mapGetters("expressionbuilder", {
      functions: "functions",
      javascriptFunctions: "javascriptFunctions",
      getRangeOfParameters: "getRangeOfParameters",
      parametersFor: "parametersFor",
      parameterCountFor: "parameterCountFor",
      isVariablesAndExpressionsCanBeFoundInType:
        "isVariablesAndExpressionsCanBeFoundInType",
      isJsonFunction: "isJsonFunction",
      functionsThatCannotBeFinal: "functionsThatCannotBeFinal",
      getFunctionLabel: "getFunctionLabel",
      isSourceARuleBuilder: "isSourceARuleBuilder",
      getHideableOperations: "getHideableOperations"
    }),

    isString() {
      return val => _.isString(val);
    },

    customNameMap() {
      let customNameRegex = new RegExp(`^${this.expressionCustomNamePrefix}`);
      return row => {
        let index = row.name.replace(this.expressionNamePrefix, "");
        return (this.expressionToCustomNameMapToUse[index] || "").replace(
          customNameRegex,
          ""
        );
      };
    },

    operationsFor() {
      return source => {
        if (
          source === "all" ||
          _.isEmpty(_.get(this.allowedOpsFor, this.source, []))
        ) {
          return this.operations;
        } else {
          return _(this.operations)
            .map(group => {
              let op = { label: group.label };
              op.operations = _.filter(group.operations, operation =>
                _.get(this.allowedOpsFor, this.source, []).includes(
                  operation.value
                )
              );
              return op;
            })
            .filter(group => group.operations.length)
            .values();
        }
      };
    },

    getExpressionCustomName() {
      let regex = new RegExp(`${this.expressionNamePrefix}\\d+$`);
      return expr => {
        if (regex.test(expr)) {
          let index = expr.replace(this.expressionNamePrefix, "");
          return this.expressionToCustomNameMap[index] || expr;
        }
        return expr;
      };
    },

    isExpressionFinal() {
      return expressionName => expressionName === this.finalExpression;
    },
    validExpressions() {
      return _.filter(
        this.expressions,
        expression => !!expression.selectedFunction
      );
    },
    nonEmptyValidExpressions() {
      return !_.isEmpty(this.validExpressions);
    },

    expressionCopyExists() {
      return !_.isEmpty(this.copiedExpressions.expressions);
    },

    showCopyOption() {
      return this.expressionsBareString !== this.resetValueBareString;
    },

    // expressionsCopied() {
    //   return (
    //     !_.isEmpty(this.copiedExpressions.expressions) &&
    //     (!this.nonEmptyValidExpressions ||
    //       this.expressionsBareString === this.resetValueBareString)
    //   );
    // },
    validExpressionNames() {
      return _.map(this.validExpressions, "name");
    },
    isExpressionNameValid() {
      return expressionName =>
        _.includes(this.validExpressionNames, expressionName);
    },
    parameterHasError() {
      return parameter => !!parameter.error;
    },

    // useful when trying to evaluate an expression
    expressionHasError() {
      return expression => {
        return (
          !!expression.error ||
          !_.includes(this.validExpressionNames, expression.name) ||
          _.some(expression.parameters, parameter =>
            this.parameterHasError(parameter)
          )
        );
      };
    },

    // useful when trying to saving the entire expressions
    expressionsHasError() {
      return (
        (this.finalExpression &&
          !_.includes(this.validExpressionNames, this.finalExpression)) ||
        _.some(this.validExpressions, expression =>
          this.expressionHasError(expression)
        )
      );
    },

    expressionsHasErrorAtRootLevel() {
      return _.some(this.expressions, expression => !!expression.error);
    },

    // useful when trying to evaluate an expression
    evaluationTreeHasError() {
      return expression => {
        let dependencies = this.getDependencies(expression);
        return _.some(dependencies, dependency => {
          let dependencyExpression = this.getExpressionByName(dependency);
          if (!_.isEmpty(dependencyExpression)) {
            return this.expressionHasError(dependencyExpression);
          }
          return false;
        });
      };
    },

    expressionsToShow() {
      return _.map(
        _.filter(this.expressions, expression => {
          return (
            !this.isSourceARuleBuilder(this.source) ||
            (this.isSourceARuleBuilder(this.source) &&
              expression.name !== "Expr1")
          );
        }),
        // this.expressions,
        expression => {
          const dependents = this.getDependents(expression);
          const { parameters } = expression;
          _.map(parameters, parameter => {
            const expressionNames = _.map(this.expressions, "name");
            if (
              this.isVariablesAndExpressionsCanBeFoundInType(parameter.type) &&
              parameter.value
            ) {
              if (
                _.some(
                  this.expressionsUsed(parameter.value),
                  dependent => !_.includes(expressionNames, dependent)
                )
              ) {
                parameter.error = "Invalid Expression found";
              } else {
                let error;
                let invalidVariables = _.difference(
                  this.variablesUsed(parameter.value),
                  [
                    ...this.variableNames,
                    ...this.getSystemVariableNames,
                    ...this.secureVariableNames,
                    ...this.xSipVariableNames,
                    ...this.cavVariablesNames
                  ]
                );

                // remove XSIP_* variables from invalid variables bag
                if (!_.isEmpty(this.xSipVariableNames)) {
                  // consider XSIP variables to be valid only if the scope allows some XSIP variables
                  // which can be determined by whether there are some xSipVariableNames
                  invalidVariables = _.filter(
                    invalidVariables,
                    invalidVariable =>
                      !/^XSIP_[\S]+/.test(invalidVariable.toString())
                  );
                }

                let invalidExpressions =
                  _.isEmpty(invalidVariables) &&
                  _.difference(
                    this.expressionsUsed(parameter.value),
                    this.validExpressionNames
                  );

                let conflictingExpressions =
                  _.isEmpty(invalidExpressions) &&
                  _.intersection(
                    this.expressionsUsed(parameter.value),
                    dependents
                  );

                if (!_.isEmpty(invalidVariables)) {
                  error =
                    invalidVariables.join(",") +
                    " - " +
                    __("invalid variables");
                } else if (!_.isEmpty(invalidExpressions)) {
                  error =
                    this.getCustomNames(invalidExpressions).join(",") +
                    " - " +
                    __("invalid expressions");
                } else if (!_.isEmpty(conflictingExpressions)) {
                  error =
                    this.getCustomNames(conflictingExpressions).join(",") +
                    " - " +
                    __("Cyclic Dependency found");
                } else {
                  error = "";
                }
                parameter.error = error;
              }
            }
          });
          return expression;
        }
      );
    },
    variablesToSetTestValues: {
      get: function() {
        return _.compact(
          _.map(
            this.variablesRequiredToEvaluateExpression({
              ...this.expressionToEvaluate,
              name: this.expressionToEvaluate.expression_to_evaluate
            }),
            variable => {
              let variableObj = _.find(this.variables, {
                variable_name: variable
              });

              if (_.isEmpty(variableObj) && /^XSIP_[\S]+/.test(variable)) {
                variableObj = {
                  variable_id: variable
                };
              }

              return _.isEmpty(variableObj)
                ? null
                : {
                    id: variableObj.variable_id,
                    variable,
                    value:
                      this.expressionToEvaluate.variables[
                        variableObj.variable_id
                      ] || ""
                  };
            }
          )
        );
      },
      set: function(variables) {
        _.map(variables, variable => {
          this.expressionToEvaluate.variables[variable.id] = variable.value;
        });
      }
    },

    jsonExpressionsToSetTestValues: {
      get: function() {
        return _.compact(
          _.map(
            this.jsonExpressionsRequiredToEvaluateExpression({
              ...this.expressionToEvaluate,
              name: this.expressionToEvaluate.expression_to_evaluate
            }),
            expressionName => {
              return {
                id: expressionName,
                variable: expressionName,
                value:
                  this.expressionToEvaluate.jsonExpressions[expressionName] ||
                  ""
              };
            }
          )
        );
      },
      set: function(expressions) {
        _.map(expressions, expression => {
          this.expressionToEvaluate.jsonExpressions[expression.id] =
            expression.value;
        });
      }
    },

    expressionNames() {
      return currentExpression => {
        return _.difference(
          this.validExpressionNames,
          this.getDependents(currentExpression)
        );
      };
    },
    getExpressionByName() {
      return name => {
        return _.find(this.expressions, { name });
      };
    },
    darkModeClass() {
      return this.darkTheme ? "dark" : "";
    },
    builderNameLan() {
      return this.isSourceARuleBuilder(this.source)
        ? __("Rule Builder")
        : __("Expression Builder");
    },
    sourceARuleBuilderLan() {
      return this.isSourceARuleBuilder(this.source)
        ? __("Evaluated Rule")
        : __("Evaluated Expression");
    }
  },
  data() {
    const EXPRESSION_NAME_PREFIX = "Expr";
    const EXPRESSION_CUSTOM_NAME_PREFIX = "expr";

    return {
      loading: true,
      userSelectedExpression: false,
      expressionNamePrefix: EXPRESSION_NAME_PREFIX,
      expressionCustomNamePrefix: EXPRESSION_CUSTOM_NAME_PREFIX,
      regExForExpression: new RegExp(
        `{{(${EXPRESSION_NAME_PREFIX}(\\d+))}}`,
        "g"
      ),
      regExForCustomExpression: new RegExp(
        `{{(${EXPRESSION_CUSTOM_NAME_PREFIX}(.+?))}}`,
        "g"
      ),
      regexForInvalidCustomNameCharacters: new RegExp(
        /[.~`!@#$%^&*\-+={}|\\/:;><?[\]'")(]/
      ),
      regExForExpressionName: new RegExp(
        `${EXPRESSION_NAME_PREFIX}(\\d+)`,
        "g"
      ),
      regExForVariable: new RegExp(`{{((?:.(?!{{))+?)(?:\\|cav)?}}`, "g"),
      drawer: false,
      expressionToEvaluate: {
        expressions: {},
        variables: {},
        jsonExpressions: {},
        expression_to_evaluate: ""
      },
      oldExpression: {},
      shownNotification: null,
      finalExpression: "",
      expressionsRemoved: false,
      parsedExpressions: [],
      expressions: [],
      expressionCount: 0,
      expressionDependencyMap: [],
      complexVariables: [],
      xsiSubscriptionRules: {},
      expressionsBareString: "{}",
      resetValueBareString: "{}",
      expressionResetHappened: false,
      dontWatchExpressionsChange: false,
      expressionToCustomNameMap: {},
      expressionToCustomNameMapBackup: {},
      expressionToCustomNameMapToUse: {},
      canSeeHideableOperations: false
    };
  },
  methods: {
    ...mapActions("expressionbuilder", {
      copyExpressions: "copyExpressions",
      clearCopyExpressions: "clearCopyExpressions",
      setExpressionValue: "setExpressionValue"
    }),
    async getCanSeeHideableOperations() {
      this.canSeeHideableOperations = await this.showFeature(
        this.$getConst("STUDIO_EXPRESSION_BUILDER_IMPROVEMENTS")
      );
    },
    filterOperations(operations) {
      return operations.filter(operation => {
        return (
          !this.getHideableOperations.includes(operation.value) ||
          this.canSeeHideableOperations
        );
      });
    },

    setExpressionToCustomNameMapToUse(row, type = "main") {
      if (type === "main") {
        this.persistCustomNameMap(row);
      }
      this.updateExpressionToCustomNameMap(type);
    },

    updateExpressionToCustomNameMap(type = "main") {
      let useMain = type === "main" && !this.expressionsHasError;
      this.expressionToCustomNameMapToUse = useMain
        ? this.expressionToCustomNameMap
        : this.expressionToCustomNameMapBackup;
    },

    getCustomNames(expressionNames) {
      return _.map(expressionNames, expressionName =>
        this.getExpressionCustomName(expressionName)
      );
    },

    updateCustomNameMap(row) {
      return val => {
        let index = row.name.replace(this.expressionNamePrefix, "");
        this.$set(
          this.expressionToCustomNameMapBackup,
          index,
          this.expressionCustomNamePrefix + val
        );
      };
    },

    validateExpressionFields() {
      _.each(this.expressions, expression => {
        _.each(expression.parameters, parameter => {
          this.validateRequiredField(parameter);
        });
      });
    },

    validateRequiredField(parameter) {
      if (parameter.required && !parameter.tempValue) {
        this.$set(parameter, "error", __("This field is required"));
      } else {
        this.$set(parameter, "error", "");
      }
    },

    validateCustomExpressionNames() {
      _.each(this.expressions, expression => {
        this.validateCustomExpressionName(expression);
      });
    },

    validateCustomExpressionName(expression) {
      expression = this.checkForEmptyCustomExpressionName(expression);

      if (!expression.error) {
        expression = this.checkIfExpressionNameEndsInUnderscore(expression);
      }

      if (!expression.error) {
        expression = this.checkForDuplicateNames(expression);
      }

      if (!expression.error) {
        expression = this.checkForInvalidCharacters(expression);
      }

      if (!expression.error) {
        expression = this.checkForSpaceCharacter(expression);
      }

      return expression;
    },

    checkForEmptyCustomExpressionName(expression) {
      expression.error = "";
      let index = expression.name.replace(this.expressionNamePrefix, "");
      let newName = this.expressionToCustomNameMapBackup[index] || "";

      if (newName === this.expressionCustomNamePrefix) {
        expression.error = __("Name cannot be empty");
      }
      return expression;
    },

    checkIfExpressionNameEndsInUnderscore(expression) {
      expression.error = "";
      let index = expression.name.replace(this.expressionNamePrefix, "");
      let newName = this.expressionToCustomNameMapBackup[index] || "";

      if (newName.charAt(newName.length - 1) === "_") {
        expression.error = __("Name cannot end in _");
      }
      return expression;
    },

    checkForInvalidCharacters(expression) {
      expression.error = "";
      let index = expression.name.replace(this.expressionNamePrefix, "");
      let newName = this.expressionToCustomNameMapBackup[index] || "";
      if (this.regexForInvalidCustomNameCharacters.test(newName)) {
        expression.error = __("Cannot contain special characters");
      }
      return expression;
    },

    checkForSpaceCharacter(expression) {
      expression.error = "";
      let index = expression.name.replace(this.expressionNamePrefix, "");
      let newName = this.expressionToCustomNameMapBackup[index] || "";
      if (/\s/.test(newName)) {
        expression.error = __("Cannot contain spaces");
      }
      return expression;
    },

    checkForDuplicateNames(expression) {
      expression.error = "";
      let index = expression.name.replace(this.expressionNamePrefix, "");
      let newName = this.expressionToCustomNameMapBackup[index] || "";

      let otherExpressions = _.filter(
        this.expressions,
        expr => expr.name !== expression.name
      );
      _.map(otherExpressions, expr => {
        let index = expr.name.replace(this.expressionNamePrefix, "");

        let customName = this.expressionToCustomNameMapBackup[index] || "";
        if (customName === newName) {
          expression.error = __("Name must be unique");
        }
      });
      return expression;
    },

    persistCustomNameMap(row) {
      this.validateCustomExpressionNames();
      if (!this.expressionsHasErrorAtRootLevel) {
        this.expressionToCustomNameMap = _.cloneDeep(
          this.expressionToCustomNameMapBackup
        );
      } else {
        let expression = _.find(this.expressions, { name: row.name });
        if (!_.isEmpty(expression) && !expression.error) {
          let index = expression.name.replace(this.expressionNamePrefix, "");
          this.expressionToCustomNameMap[
            index
          ] = this.expressionToCustomNameMapBackup[index];
        }
      }
    },

    resetCustomName(row) {
      let index = row.name.replace(this.expressionNamePrefix, "");
      this.expressionToCustomNameMapBackup[
        index
      ] = this.expressionToCustomNameMap[index];
      this.validateCustomExpressionNames();
    },

    rowColSpan({ row, columnIndex }) {
      if (row.selectedFunction === "text_input") {
        // parameter 1
        if (columnIndex === 2) {
          // span all 4 columns
          return [1, 4];
        } else if ([3, 4, 5].includes(columnIndex)) {
          // hide rest of parameter columns for text_input function
          return [0, 0];
        }
      }
      return [1, 1];
    },

    createParameterReference(row, index) {
      return `${row.name}_parameter${index}`;
    },

    calculateExpressionFieldMinWidth(index) {
      if (index === 0 && this.isSourceARuleBuilder(this.source)) {
        return "0";
      }
      return "245px";
    },

    handleUserSelection(expressionName) {
      // if the user clicks on an expression after being emptied, we still want to move the final expression
      // automatically to new expression added
      if (this.finalExpression) {
        this.userSelectedExpression = true;
      }
      this.finalExpression = expressionName;
    },

    expressionCellClass({ row, column }) {
      let classes = row.name;
      try {
        let parameterValue = +column.property;
        if (!_.isNaN(parameterValue)) {
          classes += " parameter" + parameterValue;
        }
      } catch (e) {
        // do nothing
      }
      return classes;
    },

    handleParameterChange(parameter) {
      return (func, index) => {
        // when selected object changes, we reset the rule
        if (
          func.selectedFunction === "get_array_element" &&
          parameter.value !== func.parameters[0].value
        ) {
          func.parameters[1].tempValue = "";
          func.parameters[1].value = "";
        }
        func.parameters.splice(index, 1, parameter);
      };
    },

    clearCopy() {
      this.clearCopyExpressions()
        .then(() => {
          this.updateShownNotification({
            title: "Success",
            type: "success",
            message: __("Cleared copied expression")
          });
        })
        .catch(() => {});
    },

    initiateCopyExpressions() {
      this.copyExpressions({
        expressions: this.expressions,
        userSelectedExpression: this.userSelectedExpression,
        finalExpression: this.finalExpression,
        expressionToCustomNameMap: this.expressionToCustomNameMap
      })
        .then(() => {
          this.updateShownNotification({
            type: "success",
            message: __("Expressions copied")
          });
        })
        .catch(() => {});
    },

    pasteExpressions() {
      let copiedExpressions = _.cloneDeep(this.copiedExpressions);
      this.oldExpression = {
        expressions: this.expressions,
        userSelectedExpression: this.userSelectedExpression,
        finalExpression: this.finalExpression,
        expressionToCustomNameMap: this.expressionToCustomNameMap
      };
      this.expressions = copiedExpressions.expressions;
      this.userSelectedExpression = copiedExpressions.userSelectedExpression;
      this.finalExpression = copiedExpressions.finalExpression;
      this.expressionToCustomNameMap =
        copiedExpressions.expressionToCustomNameMap;
      const h = this.$createElement;
      this.updateShownNotification({
        type: "success",
        message: h(
          "p",
          null,
          _.compact([
            h("span", null, __("Pasted Expression List")),
            h(
              "el-button",
              {
                style:
                  "border:none; background: transparent; text-decoration:underline; position:absolute; right:15px; top:10px",
                class: "plain",
                on: {
                  click: this.undoPasteExpressions
                }
              },
              "undo"
            )
          ])
        )
      });
    },

    undoPasteExpressions() {
      this.expressions = this.oldExpression.expressions;
      this.userSelectedExpression = this.oldExpression.userSelectedExpression;
      this.finalExpression = this.oldExpression.finalExpression;
      this.expressionToCustomNameMap = this.oldExpression.expressionToCustomNameMap;
      this.updateShownNotification();
    },

    setUpTestValuesForExpressionAndEvaluate(expressionToEvaluate) {
      this.expressionToEvaluate = {
        variables: this.expressionToEvaluate.variables,
        jsonExpressions: this.expressionToEvaluate.jsonExpressions,
        expression_to_evaluate: expressionToEvaluate.name
      };

      if (!this.expressionHasError(expressionToEvaluate)) {
        let variablesRequiredToEvaluateExpression = this.variablesRequiredToEvaluateExpression(
          expressionToEvaluate
        );

        let jsonExpressionsRequiredToEvaluateExpression = this.jsonExpressionsRequiredToEvaluateExpression(
          expressionToEvaluate
        );

        // console.log(jsonExpressionsRequiredToEvaluateExpression);

        if (
          !_.isEmpty(variablesRequiredToEvaluateExpression) ||
          !_.isEmpty(jsonExpressionsRequiredToEvaluateExpression)
        ) {
          if (!_.isEmpty(variablesRequiredToEvaluateExpression)) {
            this.drawer = true;
            if (_.isEmpty(this.expressionToEvaluate.variables)) {
              this.$set(this.expressionToEvaluate, "variables", {});
            }

            _.map(variablesRequiredToEvaluateExpression, variable => {
              let variableObj = _.find(this.variables, {
                variable_name: variable
              });
              if (
                !_.isEmpty(variableObj) &&
                _.isEmpty(
                  this.expressionToEvaluate.variables[variableObj.variable_id]
                )
              ) {
                this.expressionToEvaluate.variables[variableObj.variable_id] =
                  variableObj.default_value;
              }
            });
          }

          if (!_.isEmpty(jsonExpressionsRequiredToEvaluateExpression)) {
            this.drawer = true;
            if (_.isEmpty(this.expressionToEvaluate.jsonExpressions)) {
              this.$set(this.expressionToEvaluate, "jsonExpressions", {});
            }

            _.map(
              jsonExpressionsRequiredToEvaluateExpression,
              expressionName => {
                if (
                  _.isEmpty(
                    this.expressionToEvaluate.jsonExpressions[expressionName]
                  )
                ) {
                  this.expressionToEvaluate.jsonExpressions[expressionName] =
                    "";
                }
              }
            );
          }
        } else {
          this.evaluateExpression(expressionToEvaluate);
        }
      }
    },
    // method to transform the expressions object in frontend
    // to send to backend for evaluation and saving purpose
    evaluateExpression(expressionToEvaluate) {
      if (!this.expressionHasError(expressionToEvaluate)) {
        this.drawer = false;
        expressionToEvaluate.evaluating = true;
        this.parseExpressionsForBackend(expressionToEvaluate.name);

        if (this.expressionToEvaluate) {
          evaluateExpression(this.expressionToEvaluate)
            .then(({ data }) => {
              expressionToEvaluate.value = data.result;
              expressionToEvaluate.evaluating = false;
            })
            .catch(err => {
              console.log(err);
              expressionToEvaluate.evaluating = false;
            });
        } else {
          expressionToEvaluate.value = "";
          expressionToEvaluate.evaluating = false;
        }
      } else {
        console.log("expression error");
      }
    },

    saveFinalExpression(finalExpression) {
      if (
        finalExpression &&
        this.functionsThatCannotBeFinal.includes(
          finalExpression.selectedFunction
        )
      ) {
        this.$message({
          // eslint-disable-next-line
          message: __(":selected_function is not allowed to be a final expression", {
              selected_function: this.getFunctionLabel(
                finalExpression.selectedFunction
              )
            }
          ),
          type: "error"
        });
      } else {
        this.parseExpressionsForBackend();
        let testValueExceed = _.find(
          this.expressionToEvaluate.variables,
          variable => variable.length > 500
        );
        if (testValueExceed) {
          this.$message({
            type: "info",
            // eslint-disable-next-line
            message: __("detected test values exceeding a limit of 500 characters per variable test value, those test values will not be saved")
          });
          this.removeLargeTestValues();
        }

        if (this.expressionToEvaluate.expression_to_evaluate) {
          this.$emit("input", {
            expression: JSON.stringify(this.expressionToEvaluate),
            complexVariables: this.complexVariables
          });
        } else {
          this.$emit("input", {
            expression: "",
            complexVariables: this.complexVariables
          });
        }
        this.userSelectedExpression = false;
      }
    },

    saveExpression() {
      this.validateExpressionFields();
      this.validateCustomExpressionNames();
      this.$nextTick(() => {
        if (!this.expressionsHasError) {
          let finalExpression = _.find(
            this.expressions,
            expression => expression.name === this.finalExpression
          );

          // this is a valid use case (STUD-2474). saving without final expression removes the expression

          // if (!finalExpression) {
          //   this.$message({
          //     message: "You have to select an expression to evaluate",
          //     type: "error"
          //   });
          // } else

          if (!finalExpression) {
            this.$confirm(
              // eslint-disable-next-line
                __("No expression selected to evaluate. Saving will remove the expression. Continue?"),
              __("Warning"),
              {
                confirmButtonText: __("OK"),
                cancelButtonText: __("Cancel"),
                type: "warning"
              }
            ).then(() => {
              this.saveFinalExpression("");
            });
          } else {
            this.saveFinalExpression(finalExpression);
          }
        }
      });
    },

    updateShownNotification(options = null) {
      this.shownNotification && this.shownNotification.close();
      this.shownNotification = options ? this.$notify(options) : options;
    },

    removeExpression(index) {
      let expression = this.expressions[index];

      if (_.size(this.getDependents(expression)) > 1) {
        this.updateShownNotification({
          title: __("Cannot Remove"),
          message: __("There are dependent expressions"),
          type: "error"
        });
      } else {
        if (this.finalExpression === expression.name) {
          this.finalExpression = "";
        }
        this.expressionToEvaluate.expressions = {};

        if (this.validExpressionNames.includes(expression.name)) {
          this.updateShownNotification({
            title: __("Success"),
            message: __(":expressionName removed", {
              expressionName: this.getExpressionCustomName(expression.name)
            }),
            type: "success"
          });
        }

        let expressionIndex = expression.name.replace(
          this.expressionNamePrefix,
          ""
        );
        delete this.expressionToCustomNameMap[expressionIndex];

        this.expressions.splice(index, 1);
      }
    },

    parseExpressionsForBareString() {
      let expressionToEvaluate = {
        expressions: {}
      };
      _.map(this.validExpressions, expression => {
        let exp = {
          function: expression.selectedFunction
        };

        if (Object.keys(expression).includes("fixed")) {
          exp.fixed = expression.fixed || 0;
        }

        this.$set(expressionToEvaluate.expressions, expression.name, exp);

        _.map(expression.parameters, (parameter, index) => {
          const exp = expressionToEvaluate.expressions[expression.name];
          exp["parameter" + String(index + 1)] = parameter.value;
        });
      });

      this.expressionsBareString = JSON.stringify(
        expressionToEvaluate.expressions
      );
    },

    removeLargeTestValues() {
      let vm = this;
      _.forEach(this.expressionToEvaluate.variables, function(value, key) {
        vm.expressionToEvaluate.variables[key] =
          value.length > 500 ? "" : value;
      });
    },

    parseExpressionsForBackend(expressionName = this.finalExpression) {
      this.expressionToEvaluate = {
        ...this.expressionToEvaluate,
        expressions: {},
        expression_to_evaluate: expressionName
      };

      _.map(this.validExpressions, expression => {
        let exp = {
          function: expression.selectedFunction,
          isFinal: expression.name === this.finalExpression
        };

        if (Object.keys(expression).includes("fixed")) {
          exp.fixed = expression.fixed || 0;
        }

        this.$set(this.expressionToEvaluate.expressions, expression.name, exp);

        _.map(expression.parameters, (parameter, index) => {
          const exp = this.expressionToEvaluate.expressions[expression.name];
          exp["parameter" + String(index + 1)] = parameter.value;
        });
      });

      // this is a valid use case (STUD-1793). so commenting out the logic

      // // if the expression only consists of text_input with empty parameter value
      // // ignore this built expression
      // if (_.size(this.expressionToEvaluate.expressions) === 1) {
      //   let isEmptyExpression = false;
      //   _.map(this.expressionToEvaluate.expressions, expression => {
      //     if (expression.function === "text_input" && !expression.parameter1) {
      //       isEmptyExpression = true;
      //     }
      //   });
      //   if (isEmptyExpression) {
      //     this.expressionToEvaluate = {};
      //     return;
      //   }
      // }

      if (_.isEmpty(this.expressionToEvaluate.variables)) {
        this.$set(this.expressionToEvaluate, "variables", {});
      }

      this.$set(
        this.expressionToEvaluate,
        "customNames",
        this.expressionToCustomNameMap
      );

      let variablesUsed = this.variablesUsed(
        JSON.stringify(this.expressionToEvaluate.expressions)
      );

      // rewrite variable name with variable id
      let stringifiedExpression = JSON.stringify(
        this.expressionToEvaluate.expressions
      );

      let variableIdsReallyUsed = [];

      _.map(variablesUsed, variable => {
        let variableObj = _.find(this.variables, {
          variable_name: variable
        });

        if (!_.isEmpty(variableObj)) {
          let re = new RegExp(`{{${variable}(\\|variable)?}}`, "g");
          stringifiedExpression = stringifiedExpression.replace(
            re,
            `{{${variableObj.variable_id}}}`
          );
        }

        if (_.isEmpty(variableObj) && /^XSIP_[\S]+/.test(variable)) {
          variableObj = {
            variable_id: variable
          };
        }

        if (!_.isEmpty(variableObj)) {
          if (variableObj.variable_type === "cav") {
            variableIdsReallyUsed.push(`${variableObj.variable_id}|cav`);
          } else {
            variableIdsReallyUsed.push(String(variableObj.variable_id));
          }
        }

        if (!_.isEmpty(variableObj)) {
          let variableId = variableObj.variable_id;
          let variableKey =
            variableObj.variable_type === "cav"
              ? `${variableId}|cav`
              : variableId;

          if (_.isEmpty(this.expressionToEvaluate.variables[variableKey])) {
            this.$set(
              this.expressionToEvaluate.variables,
              variableKey,
              variableObj.default_value || ""
            );
          }
        }
      });

      this.expressionToEvaluate.expressions = JSON.parse(stringifiedExpression);

      let variablesDeclaredToBeUsed = _.map(
        this.expressionToEvaluate.variables,
        (val, variableName) => variableName
      );

      let nonExistingVariables = _.difference(
        variablesDeclaredToBeUsed,
        variableIdsReallyUsed
      );

      _.map(nonExistingVariables, nonExistingVariable => {
        delete this.expressionToEvaluate.variables[nonExistingVariable];
      });

      if (_.isEmpty(this.expressionToEvaluate.jsonExpressions)) {
        this.$set(this.expressionToEvaluate, "jsonExpressions", {});
      }

      let jsonExpressionsUsed = this.jsonExpressionsUsed(this.expressions);
      _.map(jsonExpressionsUsed, jsonExpression => {
        if (
          _.isEmpty(
            this.expressionToEvaluate.jsonExpressions[jsonExpression.name]
          )
        ) {
          this.expressionToEvaluate.jsonExpressions[jsonExpression.name] = "";
        }
      });

      let jsonExpressionsDeclaredToBeUsed = _.map(
        this.expressionToEvaluate.jsonExpressions,
        (val, jsonExpressionName) => jsonExpressionName
      );
      let jsonExpressionsReallyUsed = _.map(jsonExpressionsUsed, "name");

      let nonExistingJsonExpressions = _.difference(
        jsonExpressionsDeclaredToBeUsed,
        jsonExpressionsReallyUsed
      );

      _.map(nonExistingJsonExpressions, nonExistingJsonExpression => {
        delete this.expressionToEvaluate.jsonExpressions[
          nonExistingJsonExpression
        ];
      });
    },

    generateCustomNames(expressions) {
      this.expressionToCustomNameMap = {};
      _.map(expressions, (expression, name) => {
        let index = name.replace(this.expressionNamePrefix, "");
        this.expressionToCustomNameMap[index] =
          this.expressionCustomNamePrefix + index;
      });
      this.expressionToCustomNameMapToUse = _.cloneDeep(
        this.expressionToCustomNameMap
      );
      return this.expressionToCustomNameMap;
    },

    parseExpressions(expressionToEvaluate) {
      let parsedExpressions = [];
      this.expressionToEvaluate = {
        ...this.expressionToEvaluate,
        ...expressionToEvaluate
      };
      const expressions = expressionToEvaluate.expressions || [];
      this.expressionToCustomNameMap = _.cloneDeep(
        _.get(
          expressionToEvaluate,
          "customNames",
          this.generateCustomNames(expressions)
        )
      );
      _.map(expressions, (properties, name) => {
        let parsedExpression = {
          name,
          selectedFunction: properties.function,
          value: null,
          evaluating: false,
          error: ""
        };

        if (Object.keys(properties).includes("fixed")) {
          parsedExpression.fixed = properties.fixed;
        }

        parsedExpression.parameters = _.map(
          this.parametersFor(parsedExpression.selectedFunction),
          (parameter, index) => {
            return {
              ...parameter,
              tempValue: this.parseParams(
                properties["parameter" + String(index + 1)]
              ),
              value: properties["parameter" + String(index + 1)],
              error: properties.error || ""
            };
          }
        );

        if (properties.isFinal) {
          this.finalExpression = name;
          this.userSelectedExpression = true;
        }
        parsedExpressions.push(_.cloneDeep(parsedExpression));
      });
      return _.cloneDeep(parsedExpressions);
    },

    parseParams(value) {
      if (!this.isString(value)) {
        return value;
      }
      let matches = value.matchAll(this.regExForExpression);
      matches = _.map(Array.from(matches), match => {
        return match[1];
      });
      _.map(matches, match => {
        let escapedPrefix = _.escapeRegExp("{{");
        let escapedSuffix = _.escapeRegExp("}}");
        let re = new RegExp(`${escapedPrefix}(${match})${escapedSuffix}`, "g");
        let index = match.replace(this.expressionNamePrefix, "");
        value = value.replace(
          re,
          `{{${this.expressionToCustomNameMap[index]}}}`
        );
      });
      return value;
    },

    async setParameters(expression, keepExpressionParamIfAvailable = true) {
      this.$set(expression, "value", null);
      let parameters = _.map(
        this.parametersFor(expression.selectedFunction),
        (parameter, index) => {
          return {
            ...parameter,
            value:
              this.isSourceARuleBuilder(this.source) && !index
                ? "{{Expr1}}"
                : !keepExpressionParamIfAvailable
                ? parameter.default || ""
                : expression.parameters[index].value || parameter.default || "",
            tempValue:
              this.isSourceARuleBuilder(this.source) && !index
                ? this.parseParams("{{Expr1}}")
                : !keepExpressionParamIfAvailable
                ? this.parseParams(parameter.default || "")
                : this.parseParams(
                    expression.parameters[index].value ||
                      parameter.default ||
                      ""
                  )
          };
        }
      );
      this.$set(expression, "parameters", parameters);
      await this.focusOnExpressionField(expression);
    },

    async focusOnExpressionField(expression) {
      let indexToFocus = this.isSourceARuleBuilder(this.source) ? "1" : "0";
      let el = await document.getElementsByClassName(
        `${expression.name} parameter${indexToFocus}`
      );
      if (el && el.length) {
        let inputs = el[0].getElementsByTagName("input");
        if (inputs && inputs.length) {
          inputs[0].focus();
        }
      }
    },

    expressionsUsed(parameterValue) {
      parameterValue = parameterValue && parameterValue.toString();

      if (!parameterValue) {
        return [];
      }
      let matchesForExpressions = parameterValue.matchAll(
        this.regExForExpression
      );
      matchesForExpressions = _.map(
        Array.from(matchesForExpressions),
        match => match[1]
      );

      let matchesForCustomExpressions = parameterValue.matchAll(
        this.regExForCustomExpression
      );
      matchesForCustomExpressions = _.map(
        Array.from(matchesForCustomExpressions),
        match => match[1]
      );

      return _.concat(matchesForExpressions, matchesForCustomExpressions);
    },

    variablesUsed(parameterValue) {
      parameterValue = parameterValue && parameterValue.toString();

      if (!parameterValue) {
        return [];
      }
      let matches = parameterValue.matchAll(this.regExForVariable);
      let variableNames = _.map(
        _.map(Array.from(matches), match => match[1]),
        item => {
          let variable =
            _.find(this.variables, { variable_id: +item }) ||
            _.find(this.variables, { variable_name: item });
          if (!_.isEmpty(variable)) {
            return variable.variable_name;
          }
          return item;
        }
      );
      return _.uniq(
        _.difference(variableNames, this.expressionsUsed(parameterValue))
      );
    },
    variablesRequiredToEvaluateExpression(expression) {
      let variablesCollection = [];

      // if there is a json function involved, since we are giving test value to that expression directly,
      // we don't need to manually give test values to variables and expressions that it depends on
      // so we need to remove them from expression dependency list
      let dependencies = this.getDependencies(expression);
      let jsonDependencies = this.jsonExpressionsRequiredToEvaluateExpression(
        expression
      );

      let dependenciesToRemove = [];
      _.map(jsonDependencies, dependency => {
        let expression = this.getExpressionByName(dependency);
        dependenciesToRemove.push(this.getDependencies(expression));
      });

      dependenciesToRemove = _.uniq(_.flatMapDeep(dependenciesToRemove));
      dependencies = _.difference(dependencies, dependenciesToRemove);
      dependencies.push(this.getImmediateDependencies(expression));
      dependencies = _.uniq(_.flatMapDeep(dependencies));

      // now the dependencies list only has those expressions for which we need to find
      // variables that needs to have test values

      if (this.validExpressionNames.includes(expression.name)) {
        _.map(dependencies, dependency => {
          let dependentExpression = _.find(this.expressions, {
            name: dependency
          });
          if (!_.isEmpty(dependentExpression)) {
            variablesCollection.push(
              _.map(dependentExpression.parameters, parameter => {
                return ["input", "tz-dropdown"].includes(parameter.type) &&
                  parameter.value
                  ? this.variablesUsed(parameter.value)
                  : [];
              })
            );
          }
        });
      }
      return _.uniq(_.compact(_.flatMapDeep(variablesCollection)));
    },

    jsonExpressionsUsed(expressions) {
      return _.filter(expressions, expression => {
        return this.isJsonFunction(expression.selectedFunction);
      });
    },

    jsonExpressionsRequiredToEvaluateExpression(expression, visited = []) {
      let jsonExpressionsCollection = [];

      if (this.validExpressionNames.includes(expression.name)) {
        _.map(this.getImmediateDependencies(expression), dependency => {
          let dependentExpression = _.find(this.expressions, {
            name: dependency
          });
          if (
            !_.isEmpty(dependentExpression) &&
            this.isJsonFunction(dependentExpression.selectedFunction)
          ) {
            jsonExpressionsCollection.push(dependentExpression.name);
          } else if (
            !_.isEmpty(dependentExpression) &&
            !_.includes(visited, dependentExpression.name)
          ) {
            visited.push(dependentExpression.name);
            jsonExpressionsCollection.push(
              this.jsonExpressionsRequiredToEvaluateExpression(
                dependentExpression,
                visited
              )
            );
          }
        });
      }
      return _.uniq(_.compact(_.flatMapDeep(jsonExpressionsCollection)));
    },

    updateExpressionDependencyMap() {
      this.expressionDependencyMap = [];
      _.map(this.validExpressions, expression => {
        const { parameters } = expression;
        let dependencies = [];
        _.map(parameters, parameter => {
          if (this.isVariablesAndExpressionsCanBeFoundInType(parameter.type)) {
            dependencies.push(this.expressionsUsed(parameter.value));
          }
        });
        this.expressionDependencyMap.push({
          name: expression.name,
          dependencies: _.uniq(
            _.filter(_.flatMapDeep(dependencies), item => !!item)
          )
        });
      });
    },
    // the expressions that use the current expression as an immediate dependency
    getImmediateDependents(currentExpression) {
      return _.filter(
        _.cloneDeep(this.expressionDependencyMap),
        expressionDependency =>
          _.includes(expressionDependency.dependencies, currentExpression.name)
      );
    },
    // the expressions that are a dependency for evaluating expression in context
    getImmediateDependencies(currentExpression) {
      let dependencyMap = _.find(this.expressionDependencyMap, {
        name: currentExpression.name
      });
      return _.isEmpty(dependencyMap) ? [] : dependencyMap.dependencies;
    },
    getDependents(currentExpression, dependents = []) {
      // each expression is dependant on itself
      dependents.push(currentExpression.name);

      // find the expressions that use the current expression in its calculations
      const immediateDependents = this.getImmediateDependents(
        currentExpression
      );
      if (!_.isEmpty(immediateDependents)) {
        dependents = _.concat(
          dependents,
          _.map(
            immediateDependents,
            dependent =>
              !_.includes(_.flatMapDeep(dependents), dependent.name) &&
              this.getDependents(dependent, dependents)
          )
        );
      }
      return _.uniq(
        _.filter(_.flatMapDeep(dependents), item => !_.isEmpty(item))
      );
    },
    getDependencies(currentExpression, dependencies = []) {
      dependencies.push(currentExpression.name);
      const immediateDependencies = this.getImmediateDependencies(
        currentExpression
      );

      if (!_.isEmpty(immediateDependencies)) {
        dependencies = _.concat(
          dependencies,
          _.map(immediateDependencies, dependency => {
            let expression = _.find(this.expressions, { name: dependency });
            !_.includes(_.flatMap(dependencies), dependency) &&
              !_.isEmpty(expression) &&
              this.getDependencies(expression, dependencies);
          })
        );
      }
      return _.uniq(
        _.filter(_.flatMapDeep(dependencies), item => !_.isEmpty(item))
      );
    }
  },
  created() {
    getComplexVariables(this.clickedNode.task_id)
      .then(({ data }) => {
        this.complexVariables = data.data;
      })
      .catch(err => console.log(err))
      .finally(() => {
        this.loading = false;
      });
    getXsiSubscriptionRules(this.clickedNode.task_id)
      .then(({ data }) => {
        this.xsiSubscriptionRules = _.isEmpty(data.data) ? {} : data.data;
      })
      .catch(err => console.log(err))
      .finally(() => {
        this.loading = false;
      });
    //this.setExpressionValue("get_xsi_event_prop");
  },

  async mounted() {
    if (this.new) {
      if (!this.isSourceARuleBuilder(this.source)) {
        // setting up the initial parameters for expr1 for expression builder
        let expr1 = _.find(
          this.expressions,
          expression => expression.name === "Expr1"
        );
        if (!_.isEmpty(expr1)) {
          await this.setParameters(expr1);
          // await this.focusOnExpressionField(expr1);
        }
      } else {
        // setting up the initial parameters for expr2 for rule builder
        let expr2 = _.find(
          this.expressions,
          expression => expression.name === "Expr2"
        );
        if (!_.isEmpty(expr2)) {
          await this.setParameters(expr2);
          // await this.focusOnExpressionField(expr2);
        }
      }
    }
  },
  watch: {
    expressions: {
      deep: true,
      handler: function() {
        if (!this.dontWatchExpressionsChange) {
          this.expressionCount = 0;
          let names = this.validExpressionNames;
          if (!this.expressionToCustomNameMap) {
            this.expressionToCustomNameMap = {};
          }
          _.map(names, name => {
            if (name) {
              let matches = name
                .toString()
                .matchAll(this.regExForExpressionName);
              _.map(Array.from(matches), match => {
                if (!this.expressionToCustomNameMap[match[1]]) {
                  this.expressionToCustomNameMap[match[1]] =
                    this.expressionCustomNamePrefix + match[1];
                }
                if (this.expressionCount < +match[1]) {
                  this.expressionCount = +match[1];
                }
              });
            }
          });

          if (
            !this.isSourceARuleBuilder(this.source) &&
            !_.some(this.expressions, expression =>
              _.isEmpty(expression.selectedFunction)
            )
          ) {
            this.expressionCount += 1;
            if (!this.expressionToCustomNameMap[this.expressionCount]) {
              this.$set(
                this.expressionToCustomNameMapBackup,
                this.expressionCount,
                this.expressionCustomNamePrefix + this.expressionCount
              );
              this.$set(
                this.expressionToCustomNameMap,
                this.expressionCount,
                this.expressionCustomNamePrefix + this.expressionCount
              );

              this.updateExpressionToCustomNameMap("backup");
            }
            this.expressions.push({
              name: this.expressionNamePrefix + this.expressionCount,
              evaluating: false,
              error: ""
            });
          }

          if (!this.expressionResetHappened) {
            this.finalExpression =
              !_.isEmpty(this.validExpressions) && !this.userSelectedExpression
                ? _.last(this.validExpressionNames)
                : this.finalExpression;
          } else {
            this.finalExpression = "";
            this.expressionResetHappened = false;
            this.dontWatchExpressionsChange = true;
          }

          this.parseExpressionsForBareString();
          this.updateExpressionDependencyMap();
        } else {
          this.$nextTick(() => {
            this.dontWatchExpressionsChange = false;
          });
        }
      }
    },
    value: {
      immediate: true,
      handler: function(val) {
        this.userSelectedExpression = false;
        this.expressions = this.parseExpressions(JSON.parse(val));
        this.$nextTick(() => {
          this.expressionToCustomNameMapToUse = _.cloneDeep(
            this.expressionToCustomNameMap
          );
        });
      }
    },
    resetValue: {
      immediate: true,
      handler(val) {
        this.resetValueBareString = JSON.stringify(JSON.parse(val).expressions);
      }
    },
    expressionToCustomNameMap: {
      deep: true,
      immediate: true,
      handler(val) {
        this.$nextTick(() => {
          if (!this.expressionsHasErrorAtRootLevel) {
            this.expressionToCustomNameMapBackup = _.cloneDeep(val);
            this.updateExpressionToCustomNameMap("backup");
          }
        });
      }
    },
    operations: {
      immediate: true,
      async handler() {
        await this.getCanSeeHideableOperations();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/expression-builder.scss";

//All Colours in one place
.expression-container {
  background: $background;
  transition: $transition;

  .expressionBuilderTitleGroup {
    color: $title;
    transition: $transition;
  }

  .evaluate-expression {
    i {
      color: var(--theme-color);
      transition: $transition;
    }
  }

  .expression-result {
    color: $primary-text-grey;
    transition: $transition;
  }

  .expression-builder:before {
    background-color: $background !important;
    transition: $transition;
  }

  ::v-deep .el-table__row {
    &:hover {
      .el-icon-arrow-up:before {
        color: $primary-text-grey;
        transition: $transition;
      }
    }
  }

  .functionsList ::v-deep input {
    background-color: $expression-row-background;
    transition: $transition;
  }

  ::v-deep .el-table th > .cell {
    color: $evaluated-expression-title;
    transition: $transition;
  }

  ::v-deep .el-table tr {
    background: $expression-row-background;
    transition: $transition;

    .functionsList ::v-deep input {
      background: $expression-row-background;
      color: $selected-function;
      transition: $transition;
    }

    .parameters ::v-deep input {
      color: $primary-text-grey;
      background: $expression-row-background;
      transition: $transition;

      &:disabled {
        cursor: pointer !important;
      }
    }
  }

  ::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: $expression-row-hover;
    transition: $transition;

    .functionsList ::v-deep input {
      background: $expression-row-hover;
      transition: $transition;
    }

    .parameters ::v-deep input {
      background: $expression-row-hover;
      color: $primary-text-grey;
      transition: $transition;

      &:disabled {
        cursor: pointer !important;
      }
    }
  }

  ::v-deep .el-table thead.is-group th {
    background: $background;
    transition: $transition;
  }

  ::v-deep .el-table--group::after,
  .el-table--border::after {
    background-color: $background;
    transition: $transition;
  }

  ::v-deep .super-text {
    color: $super-text-grey;
    transition: $transition;
  }

  ::v-deep .el-radio__input .el-radio__inner {
    background-color: $expression-row-background;
    border-color: $super-text-grey;
    transition: $transition;
  }

  .evaluate-expression:hover {
    cursor: pointer;

    img {
      filter: $filter-green;
    }
  }

  .evaluate-expression {
    img {
      filter: $filter-grey;
    }
  }

  ::v-deep .el-radio__input.is-checked .el-radio__inner {
    border-color: var(--theme-color);
    background: var(--theme-color);
    transition: $transition;

    &:after {
      background-color: $expression-row-background;
      transition: $transition;
    }
  }

  .expression-name {
    color: $primary-text-grey;
    transition: $transition;
  }

  .variableDrawerTitle {
    color: $primary-text-grey;
    transition: $transition;
  }

  .variableDrawerSubtext {
    color: $primary-text-grey;
    transition: $transition;
  }

  ::v-deep .el-drawer {
    background-color: $expression-row-hover;
    transition: $transition;
  }
  .operationBtn {
    background-color: white;
    border-color: var(--theme-hover-color);
    color: var(--theme-hover-color);
    ::v-deep svg {
      fill: var(--theme-hover-color);

      path {
        fill: var(--theme-hover-color);
      }
    }
    &:hover {
      background-color: var(--theme-color);
      border-color: white;
      color: white;
      ::v-deep svg {
        fill: white;

        path {
          fill: white;
        }
      }
    }
    .bulkImg {
      width: 20px;
      height: 17px;
    }
  }

  .save {
    color: $expression-row-background;
    background: var(--theme-color);

    &:hover {
      background-color: var(--theme-hover-color);
      color: $expression-row-background;
    }

    &:disabled {
      background-color: var(--theme-color);
      color: white;

      &:hover {
        background-color: var(--theme-hover-color);
        color: white;
      }
    }
  }

  .cancel {
    background: #f5f5f5;
    color: #a0a8b5;

    &:hover {
      background: lightgrey;
      color: white;
    }
  }

  ::v-deep .caseSensitive.is-checked {
    ::v-deep .el-checkbox__inner {
      border-color: var(--theme-color);
      background-color: var(--theme-color) !important;
      transition: $transition;

      &:after {
        border-color: $expression-row-background;
      }
    }
  }

  ::v-deep .caseSensitive {
    ::v-deep .el-checkbox__inner {
      background-color: $expression-row-background !important;
      border-color: $super-text-grey;
      transition: $transition;
    }
  }

  ::v-deep .expression-close-icon {
    color: $primary-text-grey !important;
    transition: $transition;
  }

  ::v-deep .testValue ::v-deep input {
    background: $expression-row-background;
    color: $primary-text-grey;
    transition: $transition;
  }

  .expressionBuilderOp {
    &:hover {
      color: black;
    }
  }

  .expression-name {
    ::v-deep .el-form-item__error {
      padding-left: 40px;
    }

    ::v-deep .expression-rename-box {
      padding-block: 3px;
    }

    ::v-deep .expression-rename-box:focus-within {
      & .el-input-group__prepend {
        border: 1px solid $--color-text-primary;
        border-radius: 4px;
        color: $primary-text-grey !important;
        border-right: unset;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    ::v-deep .el-input-group__prepend {
      padding-inline: 0;
      padding-left: 5px;
      color: $--color-text-primary !important;
    }

    .expression-rename-box ::v-deep input {
      border-left: unset;
      padding-left: 0;
    }

    .expression-rename-box ::v-deep .el-input__suffix {
      top: 23px;
      right: -5px;

      .el-input__count-inner {
        background: transparent;
        color: $secondary-text-grey;
        font-size: 10px;
      }
    }

    &.is-error {
      ::v-deep .expression-rename-box {
        & .el-input-group__prepend {
          border: 1px solid $--color-error;
          border-radius: 4px;

          border-right: unset;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }
  }
}

.expression-container.dark {
  background: $dark-background;
  transition: $transition;

  .expressionBuilderTitleGroup {
    color: $dark-title;
    transition: $transition;
  }

  .evaluate-expression {
    i {
      color: var(--theme-color);
      transition: $transition;
    }
  }

  .expression-result {
    color: $dark-primary-text-grey;
    transition: $transition;
  }

  .expression-builder:before {
    background-color: $dark-background !important;
    transition: $transition;
  }

  ::v-deep .el-table__row {
    &:hover {
      .el-icon-arrow-up:before {
        color: $dark-primary-text-grey;
      }
    }

    transition: $transition;
  }

  .functionsList ::v-deep input {
    background-color: $dark-expression-row-background;
    transition: $transition;
  }

  ::v-deep .el-table th > .cell {
    color: $dark-evaluated-expression-title;
    transition: $transition;
  }

  ::v-deep .el-table tr {
    background: $dark-expression-row-background;
    transition: $transition;

    .functionsList ::v-deep input {
      background: $dark-expression-row-background;
      color: $dark-selected-function;
      transition: $transition;
    }

    .parameters ::v-deep input {
      background: $dark-expression-row-background;
      color: $dark-primary-text-grey;
      transition: $transition;
    }
  }

  ::v-deep .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: $dark-expression-row-hover;
    transition: $transition;

    .functionsList ::v-deep input {
      background: $dark-expression-row-hover;
      transition: $transition;
    }

    .parameters ::v-deep input {
      background: $dark-expression-row-hover;
      color: $dark-primary-text-grey;
      transition: $transition;
    }
  }

  ::v-deep .el-table thead.is-group th {
    background: $dark-background;
    transition: $transition;
  }

  ::v-deep .el-table--group::after,
  .el-table--border::after {
    background-color: $dark-background;
    transition: $transition;
  }

  ::v-deep .super-text {
    color: $dark-super-text-grey;
    transition: $transition;
  }

  ::v-deep .el-radio__input .el-radio__inner {
    background-color: $dark-expression-row-background;
    border-color: $dark-super-text-grey;
    transition: $transition;
  }

  .evaluate-expression:hover {
    cursor: pointer;

    img {
      filter: $dark-filter-green;
    }
  }

  .evaluate-expression {
    img {
      filter: $dark-filter-grey;
    }
  }

  ::v-deep .el-radio__input.is-checked .el-radio__inner {
    border-color: var(--theme-color);
    background: var(--theme-color);

    &:after {
      background-color: $dark-expression-row-background;
    }

    transition: $transition;
  }

  .expression-name {
    color: $primary-text-grey;
    transition: $transition;
  }

  .variableDrawerTitle {
    color: $dark-primary-text-grey;
    transition: $transition;
  }

  .variableDrawerSubtext {
    color: $dark-primary-text-grey;
    transition: $transition;
  }

  ::v-deep .el-drawer {
    background-color: $dark-expression-row-hover;
    transition: $transition;
  }

  .operationBtn {
    background-color: white;
    border-color: var(--theme-hover-color);
    color: var(--theme-hover-color);
    ::v-deep svg {
      fill: var(--theme-hover-color);

      path {
        fill: var(--theme-hover-color);
      }
    }
    &:hover {
      background-color: var(--theme-color);
      border-color: white;
      color: white;
      ::v-deep svg {
        fill: white;

        path {
          fill: white;
        }
      }
    }
    .bulkImg {
      width: 20px;
      height: 17px;
    }
  }

  .save {
    color: $dark-expression-row-background;
    background: var(--theme-color);

    &:hover {
      background-color: var(--theme-hover-color);
      color: $dark-expression-row-background;
    }

    &:disabled {
      background-color: var(--theme-color);
      color: $dark-super-text-grey;

      &:hover {
        background-color: var(--theme-hover-color);
        color: $dark-super-text-grey;
      }
    }
  }

  .cancel {
    background: $dark-expression-row-hover;
    color: white;

    &:hover {
      background: $dark-expression-row-hover;
      color: $dark-super-text-grey;
    }
  }

  ::v-deep .caseSensitive.is-checked {
    ::v-deep .el-checkbox__inner {
      border-color: var(--theme-color);
      background-color: var(--theme-color) !important;
      transition: $transition;

      &:after {
        border-color: $dark-expression-row-background;
        transition: $transition;
      }
    }
  }

  ::v-deep .caseSensitive {
    ::v-deep .el-checkbox__inner {
      background-color: $dark-expression-row-background !important;
      border-color: $dark-super-text-grey;
      transition: $transition;
    }
  }

  ::v-deep .expression-close-icon {
    color: $dark-primary-text-grey !important;
    transition: $transition;
  }

  ::v-deep .testValue ::v-deep input {
    background: $dark-expression-row-background;
    color: $dark-primary-text-grey;
    transition: $transition;
  }

  .expressionBuilderOp {
    &:hover {
      color: white;
    }
  }

  transition: $transition;
}

.expressionBuilderTitleGroup {
  display: flex;
  flex-direction: row;
  align-items: baseline;

  .expressionBuilderTitle {
    padding-left: 30px;
    margin-bottom: 0;
  }

  .expressionBuilderOp {
    cursor: pointer;
    margin-left: 20px;
  }
}

.expressionBuilderTable ::v-deep .el-form-item {
  margin-bottom: 0;
}

.expressionBuilderTable ::v-deep .el-table th.is-leaf,
.el-table td {
  border: none;
}

::v-deep .el-table--border th,
.el-table--border td {
  border: none;
}

::v-deep .el-table .el-table__body-wrapper .el-table__row td {
  border: none;
}

.expressionBuilderTable {
  width: 100%;
}

.evaluate-expression {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  i {
    font-size: 24px;
  }
}

.expressionBuilderTable ::v-deep .el-table--medium td {
  padding: 0;
}

.expressionBuilderTable ::v-deep .el-table--medium th {
  padding: 0;
}

.el-table {
  .el-form-item {
    padding-bottom: 15px;
    padding-top: 6px;
    width: 100%;
  }
}

td.function-column {
  .el-form-item {
    width: 100%;
  }
}

.expressionBuilderTable ::v-deep .el-form-item__error {
  padding-top: 1px;
  font-size: 10px;
}

.expression-result {
  font-weight: bold;
}

.expression-result-column {
  .el-form-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 2px !important;
    padding-top: 0 !important;
    padding-left: 10px;
  }
}

.functionsList ::v-deep input {
  border: none;
  padding-left: 12px !important;
  height: 18px;
}

.functionsList {
  height: 18px;

  ::v-deep .el-icon-arrow-up:before {
    content: "";
  }
}

.expressionBuilderTable {
  ::v-deep .el-table__row {
    &:hover {
      .el-icon-arrow-up:before {
        content: "\E6E1";
      }
    }
  }
}

.expression-container {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  height: 100vh;
  outline: none;

  ::v-deep .el-radio {
    margin-right: 0;
  }
}

.el-table .el-table__body-wrapper .el-table__row td {
  border: none !important;
}

::v-deep .el-table th > .cell {
  padding: 0 !important;
  padding-left: 5px !important;
}

::v-deep .el-table tr td > .cell {
  padding: 0 !important;
  padding-left: 5px !important;
}

.expression-builder {
  overflow: hidden;
  border: none;
}

::v-deep .el-table .el-table__body-wrapper .el-table__row td:first-of-type {
  border-radius: 0;
}

::v-deep .el-table .el-table__body-wrapper .el-table__row td:last-of-type {
  border-radius: 0;
}

::v-deep .super-text {
  font-weight: bold;
  font-size: 10px;
  height: 10px;
  line-height: 10px;
  padding-left: 12px;
  margin-bottom: 2px;
  margin-top: 2px;
  display: flex;
  //div {
  //  flex: 1;
  //}
}

::v-deep .el-form-item--medium .el-form-item__content {
  line-height: 26px;
}

::v-deep .el-input input {
  padding-left: 12px;
}

::v-deep .el-input--medium .el-input__inner {
  height: 26px;
  line-height: 26px;
}

::v-deep .el-input--medium .el-input__icon {
  line-height: 26px;
  font-weight: bold;
}

.el-radio {
  font-size: 16px;
  padding-top: 4px;
}

.variableDrawerTitle {
  font-size: 16px;
  font-weight: bold;
}

.variableDrawerSubtext {
  font-size: 14px;
}

::v-deep .el-drawer {
  padding-left: 15px;
  padding-right: 15px;
}

.actionButtons {
  margin-top: 20px;
  display: flex;
}

.save {
  flex: 1;
  border: none;
  height: 44px;
  color: white;
  font-weight: 200;
  font-size: 1rem;
}

.cancel {
  flex: 1;
  border: none;
  height: 44px;
  font-weight: 200;
  font-size: 1rem;
}

.el-popper.expressionPopper {
  padding: 12px;
  border: none;
}

::v-deep .parameters ::v-deep input {
  border: none;
  border-radius: 6px;
  padding-left: 12px !important;
}

::v-deep .functionsList ::v-deep input {
  border: none;
  padding-left: 12px !important;
}

::v-deep .caseSensitive {
  padding-left: 12px;
}

::v-deep .expression-close-icon {
  color: $primary-text-grey !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep .variable {
  color: $dark-variable-text;
  padding-left: 12px !important;
}

::v-deep .testValue ::v-deep input {
  border: none;
  padding-left: 12px !important;
}
</style>
<style lang="scss">
@import "~@/styles/expression-builder.scss";
//All Colors in one place

.expression-rename-box {
  padding-block: 10px;

  .el-input-group__prepend {
    padding-inline: 5px;
  }
}

.functionDropDown {
  background-color: $dropdown-background;

  .el-select-dropdown__item {
    color: $primary-text-grey;
    border-left: 1px solid $dropdown-background;
    border-right: 1px solid $dropdown-background;

    &:hover {
      background-color: $expression-row-background;
      border-left: 1px solid $dropdown-background;
      border-right: 1px solid $dropdown-background;
    }
  }

  .el-select-dropdown__item.hover {
    background-color: $expression-row-background !important;
  }

  .el-select-group__wrap:not(:last-of-type)::after {
    background: $super-text-grey;
  }

  .el-select-group__title {
    color: $super-text-grey;
  }
}

.el-popper.expressionPopper {
  background-color: $background;
  color: $super-text-grey;

  .popper__arrow {
    display: none;
  }
}

.el-popper.expressionPopper.dark {
  background-color: $dark-expression-row-hover;
  border-color: $dark-expression-row-hover;
  color: $dark-primary-text-grey;

  code {
    background-color: $dark-expression-row-background;
    border: $dark-expression-row-background;
    color: $dark-title;
  }
}

.atwho-wrap.expressionPopper.dark {
  .atwho-view {
    background-color: $dark-dropdown-background;
    color: $dark-primary-text-grey;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.254);

    .atwho-cur {
      background-color: $dark-expression-row-background;
    }

    .atwho-li {
      &:hover {
        background-color: $dark-expression-row-background;
      }
    }
  }
}

.functionDropDown.dark {
  background-color: $dark-dropdown-background;

  .el-select-dropdown__item {
    color: $dark-primary-text-grey;
    border-left: 1px solid $dark-dropdown-background;
    border-right: 1px solid $dark-dropdown-background;

    &:hover {
      background-color: $dark-expression-row-background;
      border-left: 1px solid $dark-dropdown-background;
      border-right: 1px solid $dark-dropdown-background;
    }
  }

  .el-select-dropdown__item.hover {
    background-color: $dark-expression-row-background !important;
  }

  .el-select-group__wrap:not(:last-of-type)::after {
    background: $dark-super-text-grey;
  }

  .el-select-group__title {
    color: $dark-super-text-grey;
  }
}

.functionDropDown {
  border: none;

  .el-select-dropdown__list {
    padding-bottom: 20px;

    .el-select-dropdown__item {
      font-size: 0.8rem;
      line-height: 25px;
      height: 25px;
      padding-left: 12px !important;
    }

    .el-select-group__title {
      padding-left: 16px !important;
    }

    .el-select-group .el-select-dropdown__item {
      padding-left: 16px !important;
    }

    .popper__arrow {
      display: none;
    }
  }
}
</style>
